@if (showCompletionProgress()) {
  <td
    ccTd
    ccVerticalAlignment="center"
    ccHorizontalAlignment="start"
    style="padding-right: 4px !important"
  >
    <nz-progress
      [nzFormat]="percentageFormatter"
      [nzPercent]="task.completion"
      [nzStrokeColor]="completionColor"
      nzType="circle"
      [nzWidth]="56"
    />
  </td>
}

<td
  ccTd
  ccVerticalAlignment="center"
  ccHorizontalAlignment="start"
>
  <div class="task-details-cell">
    <div class="task-details-cell__main">
      <div class="task-details-cell__main-title">
        {{ task.name }}
      </div>

      <cc-task-status-chip
        [status]="task.status"
        class="task-details-cell__main-status"
      ></cc-task-status-chip>
    </div>

    <div class="task-details-cell__metadata">
      <span class="task-details-cell__metadata-id">#{{ task.id }}</span>
      <div class="task-details-cell__metadata-divider"></div>
      <span class="task-details-cell__metadata-status">
        @if (showAssignedDateInSeparateColumn()) {
          {{
            'conversations-v4.tasks.assignedBy'
              | translate
                : {
                    name: task.assignerCompany.name,
                  }
          }}
        } @else {
          {{
            'conversations-v4.tasks.assignedByAgo'
              | translate
                : {
                    name: task.assignerCompany.name,
                    ago:
                      task.assignedAt
                      | ccDateFormatDistance
                        : {
                            strict: true,
                            locale: getTaskAssignedAtLocale(),
                          },
                  }
          }}
        }
      </span>
    </div>

    @if (task.product?.name; as productName) {
      <div class="task-details-cell__product">
        {{ productName }}
      </div>
    }
  </div>
</td>

@if (showAssignedDateInSeparateColumn()) {
  <td
    ccTd
    ccVerticalAlignment="center"
    ccHorizontalAlignment="start"
  >
    <div class="task-assigned-date-cell">{{ task.assignedAt | ccDateFormatDistance }} ago</div>
  </td>
}

@if (showAssignerCompany) {
  <td
    ccTd
    ccVerticalAlignment="center"
    ccHorizontalAlignment="start"
  >
    <div class="task-performer-cell">
      <img
        [alt]="task.assignerCompany.name"
        [ccTooltip]="task.assignerCompany.name"
        [src]="task.assignerCompany.logoUrl || 'assets/svg/common/new-company-placeholder.svg'"
        height="48px"
        width="48px"
      />

      <div class="task-performer-cell__title">
        {{ task.assignerCompany.name }}
      </div>
    </div>
  </td>
}

<td
  ccTd
  ccVerticalAlignment="center"
  ccHorizontalAlignment="center"
>
  <div class="task-performer-cell">
    <cc-user-avatar
      [src]="assigner.avatarUrl"
      [name]="[assigner.firstName, assigner.lastName]"
      [id]="assigner.id"
      [size]="24"
      [showNameTooltip]="true"
    />
  </div>
</td>

<td
  ccTd
  ccVerticalAlignment="center"
  ccHorizontalAlignment="center"
>
  <div class="task-performer-cell">
    @if (task.acceptedBy) {
      <cc-user-avatar
        [src]="assignee.avatarUrl"
        [name]="[assignee.firstName, assignee.lastName]"
        [id]="assignee.id"
        [size]="24"
        [showNameTooltip]="true"
      />
    } @else {
      <div
        [ccTooltip]="'conversations-v4.tasks.noAssignee' | translate"
        class="task-performer-cell__avatar-placeholder"
      ></div>
    }
  </div>
</td>
