import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, input, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NzProgressComponent } from 'ng-zorro-antd/progress';

import { colorFormatter, percentageFormatter } from '@clover/core/helpers/nz-progress-formatters';
import { getTaskAssignedAtLocale } from '@conversations/tasks/task-card/task-card.component';
import { TaskStatusChipComponent } from '@conversations/tasks/task-status-chip/task-status-chip.component';
import { TasksService } from '@conversations/tasks/tasks.service';
import { CoreModule } from '@core/core.module';
import { DateFormatDistancePipe } from '@core/pipes/date-format.pipe';
import { UserAvatarComponent } from '@design/misc/user-avatar/user-logo.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TdComponent } from '@design/table/td/td.component';

import { Task, TaskPreview, type TaskPerformer } from '../tasks.model';

@UntilDestroy()
@Component({
  selector: 'cc-task-table-row',
  standalone: true,
  imports: [
    TdComponent,
    CoreModule,
    TaskStatusChipComponent,
    TooltipDirective,
    DateFormatDistancePipe,
    TranslateModule,
    NzProgressComponent,
    UserAvatarComponent,
  ],
  templateUrl: './task-table-row.component.html',
  styleUrls: ['./task-table-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskTableRowComponent implements OnInit {
  @Input()
  task: TaskPreview;

  @Input()
  showAssignerCompany = true;

  showCompletionProgress = input<boolean>(false);
  showAssignedDateInSeparateColumn = input<boolean>(false);

  protected readonly getTaskAssignedAtLocale = getTaskAssignedAtLocale;
  protected readonly percentageFormatter = percentageFormatter;

  private readonly tasksService = inject(TasksService);
  private readonly cdr = inject(ChangeDetectorRef);

  get completionColor(): string {
    return colorFormatter(this.task.completion);
  }

  get assigner(): TaskPerformer {
    return this.task.assigner;
  }

  get assignee(): TaskPerformer {
    return this.task.acceptedBy;
  }

  ngOnInit(): void {
    this.tasksService
      .subscribeToTaskUpdates(this.task.id)
      .pipe(untilDestroyed(this))
      .subscribe((task: Task): void => {
        this.task = task;
        this.cdr.detectChanges();
      });
  }
}
